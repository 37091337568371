@import "../config/config";

.index-content {
  display: flex;
  flex-direction: column;
}

.entry-section {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: elemEmSize(10, 35);

  @media (max-width: 450px) {
    padding-left: 0;
    padding-right: 0;
  }
}

.entry-form__wrapper {
  width: 100%;
  max-width: elemEmSize(10, 500);
  font-size: 1rem;
  margin: 0 auto;

  @media (max-width: 1650px) {
    font-size: calculateBlockFZ(1.8, 18, 1650);
  }

  @media (max-width: 1366px) {
    font-size: calculateBlockFZ(1.8, 18, 1366);
  }

  @media (max-width: 1024px) {
    font-size: calculateBlockFZ(1.8, 18, 1024);
  }

  @media (max-width: 768px) {
    font-size: calculateBlockFZ(1.8, 16, 768);
  }

  @media (max-width: 550px) {
    font-size: 9px;
  }
}

.entry-form__heading {
  font-size: elemEmSize(10, 25);
  font-weight: 800;
  text-transform: uppercase;
  text-align: center;
  padding: elemEmSize(25, 15) elemEmSize(25, 10);
  color: $light-color;
  background-image: url("../../../images/form-heading-bg.jpg");
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
}

.entry-form__content {
  padding: elemEmSize(10, 50) elemEmSize(10, 60);
  padding-top: elemEmSize(10, 30);
  background-color: #E1EEEE;

  @media (max-width: 450px) {
    padding: 30px 20px;
    padding-top: 10px;
  }
}

.entry-form__row {

  &:not(:last-of-type) {
    margin-bottom: elemEmSize(10, 30);
  }

  @media (max-width: 450px) {
    flex-direction: column;
    align-items: flex-start;

    &:not(:last-of-type) {
      margin-bottom: 10px;
    }
  }
}

.entry-form__label {
  width: 100%;
  max-width: elemEmSize(18, 95);
  margin-right: elemEmSize(18, 5);
  flex-shrink: 0;

  @media (max-width: 450px) {
    margin-right: 0;
    max-width: 100%;
  }
}

.entry-form__form-items {
  margin-left: -20px;

  @media (max-width: 450px) {
    margin-left: 0;
  }
}

.entry-form__item-wrapper {
  width: calc(33.3333% - 20px);
  margin-left: 20px;

  @media (max-width: 450px) {
    flex: 1;
    margin-left: 0;

    &:not(:last-of-type) {
      margin-right: 20px;
    }
  }
}

.entry-form__link {
  font-size: elemEmSize(10, 18);
  position: relative;
  display: inline-block;

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    height: 1px;
    background-color: $accent2-color;
    margin-top: elemEmSize(18, -4);
    transition: width .3s ease;
  }

  &:link,
  &:visited {
    text-decoration: none;
    color: $accent2-color;
  }

  &:hover {

    &::after {
      width: 0;
    }
  }
}

.entry-form__submit {

  @media (max-width: 450px) {
    margin-top: 30px;
  }
}