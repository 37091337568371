@import "../config/config";

.wrapper {
  background-image: url("../../../images/index-bg.jpg");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center top;
  position: relative;

  &--bg-disabled {
    background-image: none;
  }

  &::after {
    content: "";
    display: block;
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    background-color: rgba($dark-color, .65);
    opacity: 0;
    visibility: hidden;
    transition: opacity .4s ease, visibility .4s ease;
  }
}

.mm-opened {

  @media (max-width: $bp4) {

    &::after {
      opacity: 1;
      visibility: visible;
    }
  }
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}