@import "../config/config";

.site-input {
  display: block;
  width: 100%;
  border-radius: 3px;
  border: 1px solid #C8C8C8;
  font-size: elemEmSize(10, 18);
  padding: elemEmSize(18, 6) elemEmSize(18, 5);

  @media (min-width: 1921px) {
    border-width: elemEmSize(10, 1);
  }

  @media (max-width: $mobile-bp) {
    font-size: 16px;
    padding: 7px 5px;
  }

  &--increase-pt {
    padding: elemEmSize(18, 12) elemEmSize(18, 20);

    @media (max-width: $mobile-bp) {
      padding: 11px 15px;
    }
  }

  &--with-icon {
    padding-left: elemEmSize(18, 71);
  }
}

.input-wrapper {
  display: block;
  position: relative;
}

.input-icon {
  width: elemEmSize(10, 36);
  height: elemEmSize(10, 36);
  margin-right: elemEmSize(10, 15);
  fill: $grey-color;
  display: block;

  &--positioned {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: elemEmSize(10, 20);
  }
}

.form-label {
  font-size: elemEmSize(10, 18);
  color: $grey-color;
}

.form-row {
  display: flex;
  align-items: center;

  @media (max-width: $mobile-bp) {
    display: block;
  }
}

.form-item {
  flex: 1;
  max-width: 100%;

  @media (max-width: 450px) {
    width: 100%;
  }
}

.form-items {
  flex: 1;
  display: flex;

  @media (max-width: 450px) {
    display: flex;
    flex-basis: 100%;
  }
}

.form-item__wrapper {

}

select {
  option {
    font-size: 16px;
  }
}

.custom-select {
  width: 100%;
  max-width: 100%;

  .selectr-placeholder {
    font-size: elemEmSize(10, 18);
    text-align: center;
    color: #C8C8C8;
  }

  .selectr-selected {
    padding: elemEmSize(10, 4) elemEmSize(10, 5);
    border-color: #C8C8C8;
    border-radius: 3px;

    @media (max-width: $mobile-bp) {
      padding-left: 10px;
      padding-right: 10px;
    }
  }

  .selectr-label {
    font-size: elemEmSize(10, 18);
    text-align: center;
    padding-right: elemEmSize(18, 13);
  }

  .selectr-option {
    font-size: elemEmSize(10, 18);
    text-align: center;
    padding-left: elemEmSize(18, 5);
    padding-right: elemEmSize(18, 5);
    width: auto;
  }

  .selectr-options {
    overflow-y: visible;
    top: 0;
    height: auto;
    max-height: none;
    overflow-x: visible;
  }

  .selectr-options-container {
    width: auto;
    min-width: calc(100% + 25px);
    max-height: 200px;
    position: absolute;
    left: 0;
    top: 100%;
  }

  .selectr-selected::before {
    right: elemEmSize(10, 5);
    border-width: elemEmSize(10, 10) elemEmSize(10, 6) 0;
    border-style: solid;
    border-color: #C8C8C8 transparent transparent;
    z-index: -1;
  }

  &.selectr-container.native-open .selectr-selected::before,
  &.selectr-container.open .selectr-selected::before {
    border-width: 0 elemEmSize(10, 6) elemEmSize(10, 10);
    border-style: solid;
    border-color: transparent transparent #C8C8C8;
  }

  .simplebar-scrollbar:before {
    background-color: $accent1-color;
  }
}

.select-padding-x {
  .selectr-selected {
    padding: elemEmSize(10, 4) elemEmSize(10, 20);
  }

  .selectr-selected::before {
    right: elemEmSize(10, 20);
  }
}

.select-padding-y {
  .selectr-selected {
    padding: elemEmSize(10, 9.5) elemEmSize(10, 20);
  }
}

.custom-icon-select {
  display: flex;
  align-items: center;
  padding-right: elemEmSize(18, 40);
}

.custom-icon-select__icon {
  width: elemEmSize(18, 36);
  height: elemEmSize(18, 36);
  flex-shrink: 0;
  fill: $grey-color;
  margin-right: elemEmSize(18, 15);
}

.custom-icon-select__text {
  flex: 1;
  text-align: left;
  color: $grey-color;
  white-space: nowrap;
  text-overflow: ellipsis;
}