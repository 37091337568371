@function calculateBlockFZ($current_em_size, $future_px_size, $current_vw) {
  $future_base: $future_px_size / $current_em_size;
  $future_vw_size: ($future_base * 100 / $current_vw) * 1vw; 

  @return $future_vw_size;
}

@function elemEmSize($block_base, $font_size) {
  @return ($font_size / $block_base) * 1em;
}

@mixin font-face($font-name, $folder-name, $file-name, $weight: normal, $style: normal) {
  @font-face {
    font-family: quote($font-name);
    src: url("../../../fonts/#{$folder-name}/#{$file-name}.woff") format("woff"),
    url("../../../fonts/#{$folder-name}/#{$file-name}.woff2") format("woff2");
    font-weight: $weight;
    font-style: $style;
  }
}

@mixin responsive-img() {
  display: block;
  max-width: 100%;
  height: auto;
  width: 100%;
}

