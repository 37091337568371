// Colors
$light-color: #fff;
$dark-color: #2B2B2B;
$grey-color: #7A7A7A;
$danger-color: #C80C0C;
$accent1-color: #00DDCC;
$accent2-color: #047984;

// Breakpoints
$bp1: 1650px;
$bp1-size: 9.99999;
$bp2: 1366px;
$bp2-size: 9.44439;
$bp3: 1024px;
$bp3-size: 9.44445;
$bp4: 768px;
$bp4-size: 9.44448;
$bp5: 600px;
$bp5-size: 9.44442;
$mobile-bp: 440px;
