@import "../config/config";

.button {
  display: inline-block;
  min-width: elemEmSize(20, 120);
  font-size: elemEmSize(10, 20);
  color: $light-color;
  background-color: $accent1-color;
  text-align: center;
  text-transform: uppercase;
  padding: elemEmSize(20, 8) elemEmSize(20, 5);
  border: 0;
  cursor: pointer;
  box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
  transition: box-shadow .3s ease, transform .3s ease;
  backface-visibility: hidden;
  font-weight: 700;
  line-height: (28.4 / 20);

  &:link,
  &:visited {
    text-decoration: none;
    color: $light-color;
  }

  &:hover {
    box-shadow: 5px 7px 8px rgba(0, 0, 0, 0.25);
    transform: translateY(-2px);
  }

  &:active {
    box-shadow: 5px 5px 4px rgba(0, 0, 0, 0.25);
    transform: translateY(0);
  }

  &:focus {
    outline: none;
  }

  &--full-width {
    display: block;
    width: 100%;
    max-width: 100%;
  }

  @media (min-width: 1921px) {
    box-shadow: elemEmSize(20, 5) elemEmSize(20, 5) elemEmSize(20, 4) rgba(0, 0, 0, 0.25);
  }

  @media (max-width: 450px) {
    padding: 10px;
    font-size: 18px;
  }
}