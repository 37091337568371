@import "../config/config";

.page-loader__wrapper {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #D5E8E8;
  transform: scaleX(1);
  opacity: 1;
  transition: transform .2s ease-out, opacity .2s ease-out;

  &--hidden {
    transform: scaleX(0);
    opacity: 0;
  }
}

.page-loader {
  transition: transform .3s ease-in-out, opacity .3s ease-in-out;
  opacity: 1;
  transform: translateY(0);

  &--hidden {
    opacity: 0;
    transform: translateY(-80px);
  }
}

.page-loader__icon {
  // w:426, h: 380
  width: elemEmSize(10, 280);
  height: elemEmSize(10, 280 * (380 / 426));
  overflow: visible;
  display: block;

  @media (max-width: $bp1) {
    width: elemEmSize($bp1-size, 280);
    height: elemEmSize($bp1-size, 280 * (380 / 426));
  }

  @media (max-width: $bp2) {
    width: elemEmSize($bp2-size, 200);
    height: elemEmSize($bp2-size, 200 * (380 / 426));
  }

  @media (max-width: $bp3) {
    width: elemEmSize($bp3-size, 180);
    height: elemEmSize($bp3-size, 180 * (380 / 426));
  }
}

.loader-star-1 {
  animation-name: flyToTop1;
  animation-duration: .8s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.loader-star-2 {
  animation-name: flyToTop2;
  animation-duration: 1.3s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.loader-star-3 {
  animation-name: flyToTop1;
  animation-duration: 1s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.loader-star-4 {
  animation-name: flyToTop2;
  animation-duration: .9s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

.loader-star-5 {
  animation-name: flyToTop1;
  animation-duration: .8s;
  animation-timing-function: ease;
  animation-iteration-count: infinite;
}

@keyframes flyToTop1 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-35px);
  }
}

@keyframes flyToTop2 {
  0% {
    opacity: 1;
    transform: translateY(0);
  }

  100% {
    opacity: 0;
    transform: translateY(-45px);
  }
}