.swal2-container {
  .swal2-popup {
    font-size: 15px;
  }

  .swal2-actions {
    font-size: 10px;
  }
}

body.swal2-height-auto.swal2-shown {
  height: 100% !important;
}